import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col, Card, Carousel, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../core/data-manager';
import team from '../assets/team.jpeg';
import bio_linkedin from '../assets/social/bio-linkedin.png';
import bio_whatsapp from '../assets/social/bio-whatsapp.png';
import bio_email from '../assets/social/bio-email.png';
import bio_website from '../assets/social/bio-website.png';
import bio_instagram from '../assets/social/bio-instagram.png';



export default function Home() {

    function scrollTo(div) {
        const section = document.getElementById(div);
        section.scrollIntoView({ behavior: 'smooth' });
    }


    const [showBio, setShowBio] = useState(false);
    const [bio, setBio] = useState({});
    function handleCloseBio() {
        setShowBio(false)
        setBio({})
    }
    function handleShowBio(i) {
        setBio(data[i])
        setShowBio(true)
    }

    const [modalCollabora, setModalCollabora] = useState(false);
    function openModalCollabora() {
        setModalCollabora(true)
    }



    const [data, setData] = useState(false);

    useEffect(() => {
        async function init() {
            var response = await DataManager.getItems('profile', 1, 100, { field: 'id', order: 'desc' });
            console.log("response", response);
            setData(response.body)
        }

        init();
    }, []);



    return (
        <>
            <Helmet>
                <title>Health 51 - Nutrizione, Psicologia, Fisioterapia - Conegliano</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='px-0 d-flex justift-content-center align-items-center' id="hero-home">
                <Container fluid className='text-center'>
                    <Row className='align-items-center' style={{ backgroundColor: 'rgba(240, 237, 237, 0.5)' }}>
                        <Col xs={12} md={6} className='m-0 p-0'>
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={team}
                                        alt="First slide"
                                    />
                                    {/*
                                    <Carousel.Caption>
                                        <h3>First Slide</h3>
                                        <p>This is the first slide description.</p>
                                    </Carousel.Caption>
                                    */}
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col xs={12} md={6} className='text-center p-0'>
                            <div className='py-5 px-5'>
                                {/*
                                <h1>Il nostro centro</h1>
                                <p className='mx-auto' style={{ maxWidth: 700 }}>
                                    Health51 nasce con l’obiettivo di offrirti un approccio integrato alla salute e al benessere. Nel nostro centro, ci prendiamo cura di te a 360 gradi, combinando le migliori pratiche di fisioterapia, psicologia e nutrizione per supportarti nel raggiungimento di un equilibrio fisico, mentale e nutrizionale ottimale. Ogni percorso è personalizzato per rispondere alle tue esigenze specifiche, aiutandoti a vivere una vita più sana, serena e appagante. Siamo qui per accompagnarti in ogni passo del tuo percorso verso il benessere.
                                </p>
                                */}
                                <p className='mx-auto' style={{ maxWidth: 700 }}>
                                    Health51 è un ambiente pensato per nutrizionisti, psicologi e fisioterapisti che desiderano esercitare la loro professione in un ambiente professionale e accogliente. Mettiamo a disposizione studi attrezzati per supportare al meglio il lavoro dei professionisti, offrendo soluzioni personalizzate e una gestione completa degli spazi. Il nostro obiettivo è creare un contesto ideale per fornire servizi di qualità, dove ogni professionista possa concentrarsi sulla cura e sul benessere dei propri clienti, senza doversi preoccupare degli aspetti logisitici. Un ambiente moderno, funzionale e sereno, dove il benessere di tutti è al centro.
                                </p>
                                <p>
                                    <b>Ci trovi in:</b>
                                    <br />
                                    Via Venezia, 57/A
                                    <br />
                                    31020, San Vendemiano (TV)
                                </p>
                                <Button size="sm" variant='outline-dark' onClick={() => scrollTo('footer')}>Collabora con noi</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col onClick={() => scrollTo('nutrizione')} xs={12} md={4} style={{ backgroundColor: '#FFC63B' }} role="button">
                            <div className='py-5'>
                                <h2>1.</h2>
                                <span className='mx-2'>
                                    Nutrizione <FontAwesomeIcon icon="arrow-right" size={'1x'} className='ms-2' color='#000' />
                                </span>
                            </div>
                        </Col>
                        <Col onClick={() => scrollTo('psicologia')} xs={12} md={4} style={{ backgroundColor: '#73B9C0' }} role="button">
                            <div className='py-5'>
                                <h2>2.</h2>
                                <span className='mx-2'>
                                    Psicologia <FontAwesomeIcon icon="arrow-right" size={'1x'} className='ms-2' color='#000' />
                                </span>
                            </div>
                        </Col>
                        <Col onClick={() => scrollTo('fisioterapia')} xs={12} md={4} style={{ backgroundColor: '#D289B8' }} role="button">
                            <div className='py-5'>
                                <h2>3.</h2>
                                <span className='mx-2'>
                                    Fisioterapia <FontAwesomeIcon icon="arrow-right" size={'1x'} className='ms-2' color='#000' />
                                </span>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>


            <Container fluid className='py-5 px-0' style={{ backgroundColor: 'rgb(227 244 244)' }} id="nutrizione">
                <Container fluid='lg' className='text-start pt-5'>
                    <div className='d-flex align-items-center'>
                        <span style={{ backgroundColor: '#FFC63B' }} className='number-slide me-3'>
                            1.
                        </span>
                        <h1 style={{ lineHeight: '70px' }} className='m-0'>Nutrizione</h1>
                    </div>


                    <Row className='mt-5 text-start'>
                        {data && data.length > 0 ? (
                            <>
                                {data.map((item, i) => {
                                    if (item.type && item.type.value === 'nutrizione')
                                        return (
                                            <Col key={item.id} sm={12} md={4} lg={4} className='mb-5'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={5}>
                                                                <Image src={'https://health51.marcocekada.com/upload/profile/' + item.image.hash + '.' + item.image.extension} width={'100%'} />
                                                            </Col>
                                                            <Col xs={7}>
                                                                <h4>{item.name}</h4>
                                                                <p>
                                                                    {item.role}
                                                                </p>
                                                                <p>
                                                                    <FontAwesomeIcon icon="phone" size={'1x'} className='me-2' color='#ddd' /> {item.phone}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="envelope" size={'1x'} className='me-2' color='#ddd' /> {item.email}
                                                                </p>
                                                                <span onClick={() => handleShowBio(i)} role="button" className='call-to-action' data-type={item.type && item.type.value}>
                                                                    Scopri di più
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                })}
                            </>
                        ) : null}
                    </Row>
                </Container>
            </Container>


            <Container fluid className='py-5 px-0' style={{ backgroundColor: 'rgb(227 244 244)' }} id="psicologia">
                <Container fluid='lg' className='text-start'>
                    <div className='d-flex align-items-center'>
                        <span style={{ backgroundColor: '#73B9C0' }} className='number-slide me-3'>
                            2.
                        </span>
                        <h1 style={{ lineHeight: '70px' }} className='m-0'>Psicologia</h1>
                    </div>


                    <Row className='mt-5 text-start'>
                        {data && data.length > 0 ? (
                            <>
                                {data.map((item, i) => {
                                    if (item.type && item.type.value === 'psicologia')
                                        return (
                                            <Col key={item.id} sm={12} md={4} lg={4} className='mb-5'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={5}>
                                                                <Image src={'https://health51.marcocekada.com/upload/profile/' + item.image.hash + '.' + item.image.extension} width={'100%'} />
                                                            </Col>
                                                            <Col xs={7}>
                                                                <h4>{item.name}</h4>
                                                                <p>
                                                                    {item.role}
                                                                </p>
                                                                <p>
                                                                    <FontAwesomeIcon icon="phone" size={'1x'} className='me-2' color='#ddd' /> {item.phone}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="envelope" size={'1x'} className='me-2' color='#ddd' /> {item.email}
                                                                </p>
                                                                <span onClick={() => handleShowBio(i)} role="button" className='call-to-action' data-type={item.type && item.type.value}>
                                                                    Scopri di più
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                })}
                            </>
                        ) : null}
                    </Row>
                </Container>
            </Container>


            <Container fluid className='py-5 px-0' style={{ backgroundColor: 'rgb(227 244 244)' }} id="fisioterapia">
                <Container fluid='lg' className='text-start'>
                    <div className='d-flex align-items-center'>
                        <span style={{ backgroundColor: '#D289B8' }} className='number-slide me-3'>
                            3.
                        </span>
                        <h1 style={{ lineHeight: '70px' }} className='m-0'>Fisioterapia</h1>
                    </div>


                    <Row className='mt-5 text-start'>
                        {data && data.length > 0 ? (
                            <>
                                {data.map((item, i) => {
                                    if (item.type && item.type.value === 'fisioterapia')
                                        return (
                                            <Col key={item.id} sm={12} md={4} lg={4} className='mb-5'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={5}>
                                                                <Image src={'https://health51.marcocekada.com/upload/profile/' + item.image.hash + '.' + item.image.extension} width={'100%'} />
                                                            </Col>
                                                            <Col xs={7}>
                                                                <h4>{item.name}</h4>
                                                                <p>
                                                                    {item.role}
                                                                </p>
                                                                <p>
                                                                    <FontAwesomeIcon icon="phone" size={'1x'} className='me-2' color='#ddd' /> {item.phone}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="envelope" size={'1x'} className='me-2' color='#ddd' /> {item.email}
                                                                </p>
                                                                <span onClick={() => handleShowBio(i)} role="button" className='call-to-action' data-type={item.type && item.type.value}>
                                                                    Scopri di più
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                })}
                            </>
                        ) : null}
                    </Row>
                </Container>
            </Container>


            <Modal
                show={showBio}
                onHide={handleCloseBio}
                backdrop="static"
                keyboard={false}
                size="lg"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{bio.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0 bio-modal-body'>
                    <Container fluid>
                        {bio.type && bio.type.value === 'fisioterapia' ? (
                            <Row style={{ backgroundColor: 'rgb(210, 137, 184)' }} className='align-items-center'>
                                <Col md={4} className='p-5'>
                                    <Image src={'https://health51.marcocekada.com/upload/profile/' + bio.image.hash + '.' + bio.image.extension} width={'100%'} roundedCircle={true} />
                                </Col>
                                <Col md={8} className='p-5 text-white' style={{ fontSize: 18 }}>
                                    <div dangerouslySetInnerHTML={{ __html: bio.mission }}></div>
                                </Col>
                            </Row>
                        ) : null}

                        {bio.type && bio.type.value === 'nutrizione' ? (
                            <Row style={{ backgroundColor: '#FFC63B' }} className='align-items-center'>
                                <Col md={4} className='p-5'>
                                    <Image src={'https://health51.marcocekada.com/upload/profile/' + bio.image.hash + '.' + bio.image.extension} width={'100%'} roundedCircle={true} />
                                </Col>
                                <Col md={8} className='p-5 text-white' style={{ fontSize: 18 }}>
                                    <div dangerouslySetInnerHTML={{ __html: bio.mission }}></div>
                                </Col>
                            </Row>
                        ) : null}

                        {bio.type && bio.type.value === 'psicologia' ? (
                            <Row style={{ backgroundColor: '#73B9C0' }} className='align-items-center'>
                                <Col md={4} className='p-5'>
                                    <Image src={'https://health51.marcocekada.com/upload/profile/' + bio.image.hash + '.' + bio.image.extension} width={'100%'} roundedCircle={true} />
                                </Col>
                                <Col md={8} className='p-5 text-white' style={{ fontSize: 18 }}>
                                    <div dangerouslySetInnerHTML={{ __html: bio.mission }}></div>
                                </Col>
                            </Row>
                        ) : null}


                        <Row className='mt-5'>
                            <Col md={12} className='text-center'>
                                <h2>Di cosa mi occupo</h2>
                                <div className='text-start p-5'>
                                    <div dangerouslySetInnerHTML={{ __html: bio.work }}></div>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-4' style={{ backgroundColor: '#eee' }}>
                            <Col md={12} className='text-center p-5'>
                                <h4>Formazione</h4>
                                <div className='small mt-3 text-start'>
                                    <div dangerouslySetInnerHTML={{ __html: bio.experience }}></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                    <Container fluid className='text-center mt-3 mb-3'>
                        {bio.whatsapp ? (
                            <>
                                <span className='bio-btn'>
                                    <a href={"https://wa.me/" + bio.whatsapp}>
                                        <Image src={bio_whatsapp} width={50} />
                                    </a>
                                </span>
                            </>
                        ) : null}
                        {bio.email ? (
                            <>
                                <span className='bio-btn'>
                                    <a href={"mailto:" + bio.email}>
                                        <Image src={bio_email} width={50} />
                                    </a>
                                </span>
                            </>
                        ) : null}
                        {bio.linkedin ? (
                            <>
                                <span className='bio-btn'>
                                    <a href={bio.linkedin}>
                                        <Image src={bio_linkedin} width={50} />
                                    </a>
                                </span>
                            </>
                        ) : null}
                        {bio.website ? (
                            <>
                                <span className='bio-btn'>
                                    <a href={bio.website}>
                                        <Image src={bio_website} width={50} />
                                    </a>
                                </span>
                            </>
                        ) : null}
                        {bio.instagram ? (
                            <>
                                <span className='bio-btn'>
                                    <a href={bio.instagram}>
                                        <Image src={bio_instagram} width={50} />
                                    </a>
                                </span>
                            </>
                        ) : null}
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseBio}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>


            
            {/*
            <Modal
                show={modalCollabora}
                onHide={() => setModalCollabora(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Collabora con noi</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'rgb(127 198 187)' }}>
                    <p className='text-white p-5'>
                        Health51 è un ambiente pensato per nutrizionisti, psicologi e fisioterapisti che desiderano esercitare la loro professione in un ambiente professionale e accogliente. Mettiamo a disposizione studi attrezzati per supportare al meglio il lavoro dei professionisti, offrendo soluzioni personalizzate e una gestione completa degli spazi. Il nostro obiettivo è creare un contesto ideale per fornire servizi di qualità, dove ogni professionista possa concentrarsi sulla cura e sul benessere dei propri clienti, senza doversi preoccupare degli aspetti logisitici. Un ambiente moderno, funzionale e sereno, dove il benessere di tutti è al centro.
                    </p>
                </Modal.Body>
            </Modal>
            */}
        </>
    )

};